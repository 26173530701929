import React from 'react'
import './logo.css'

function Logo() {
  return (
    <div className="logo container" id='logo'>
      <h3>Sondo<span>sjs</span></h3>
    </div>
  )
}

export default Logo
